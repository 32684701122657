<template>
  <div class="container is-max-desktop">
    <div class="buttons is-centered">
      <router-link to="/goal-setting" class="button is-large">It's making more sense now</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643814392/rendition/1080p?loc=external&signature=878dd1d8ef11090a30a1937d4e1ec2fa6cf9b97e9914c1e45f3c0b4d9713f6b4'
        vm.$root.videos.next = ['643814549/rendition/1080p?loc=external&signature=d210080ea769c2365491d06cee6b712e65a705ec44b6cddbfe15876ca82ec335']
      })
    }
  }
</script>
