<template>
  <div class="container is-max-desktop">
    <div class="block">
      <h2 class="title has-text-centered has-text-white">Goal: {{ sessionNotes.goalSetting.goal }}</h2>
    </div>

    <div class="block">
      <h2 class="subtitle has-text-centered has-text-white">First Small Step: {{ sessionNotes.goalSetting.firstStep }}</h2>
    </div>

    <div class="block">
      <h2 class="subtitle has-text-centered has-text-white">Second Small Step: {{ sessionNotes.goalSetting.secondStep }}</h2>
    </div>

    <div class="buttons is-centered">
      <router-link to="/conclusion" class="button is-large">OK, I have a commitment in mind</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        sessionNotes: this.$root.sessionNotes
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '666910298/rendition/1080p?loc=external&signature=7170345fcf950e37c09d89e921acbd7461c69e4c512881c2269f97a00bfb05eb'
        vm.$root.videos.next = ['643823523/rendition/1080p?loc=external&signature=0b1060c9be8fbee7f7342cefe42a1eb0bc29b4d47a84fcd6c4eca6d15b489559']
      })
    }
  }
</script>
