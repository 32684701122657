<template>
  <div class="container is-max-desktop">
    <div class="columns">
      <div class="column">
        <h1 class="title has-text-centered has-text-white">
          Would you like a recap on what we have covered so far, or to move on?
        </h1>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="buttons is-centered">
          <router-link to="/summary-so-far" class="button is-large">Recap</router-link>
          <router-link to="/goal-setting" class="button is-large">Next step</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '666909932/rendition/1080p?loc=external&signature=2cb6f94a94c8a789f54b344b7432487f33370769faa5cd11498b7f39f99f9c3d'
        vm.$root.videos.next = [
          '643814392/rendition/1080p?loc=external&signature=878dd1d8ef11090a30a1937d4e1ec2fa6cf9b97e9914c1e45f3c0b4d9713f6b4',
          '643814549/rendition/1080p?loc=external&signature=d210080ea769c2365491d06cee6b712e65a705ec44b6cddbfe15876ca82ec335'
        ]
      })
    }
  }
</script>
