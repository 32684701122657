<template>
  <div class="container is-max-desktop">
    <div class="block" v-if="remaining.length > 0">
      <h1 class="title has-text-centered has-text-white" v-if="remaining.length === 2">
        What skill would you like to learn about next?
      </h1>
      <h1 class="title has-text-centered has-text-white" v-else>
        There's one last skill that would be useful to go over
      </h1>
    </div>
    <div class="block">
      <div class="buttons is-centered">
        <button class="button is-large" v-for="video in remaining" :key="video" @click="play(video)">{{ buttonTitle(video) }}</button>
        <router-link to="/bridge-to-action" class="button is-large" v-if="remaining.length === 0">
          OK, I've got a grasp on each skill now
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import pull from 'lodash.pull'
  import map from 'lodash.map'

  export default {
    data() {
      return {
        remaining: ['breathing', 'grounding', 'mindfulness'],
        videos: {
          breathing: {
            title: 'Breathing',
            url: '643814114/rendition/1080p?loc=external&signature=48497c3f59cacfaab56f6ef1faf9c2a8ab15131f145615c878c58ec1780d22d6'
          },
          grounding: {
            title: 'Grounding',
            url: '643813071/rendition/1080p?loc=external&signature=acb99606008b0587b8d71e21b2db56ef4a662b60137740a3355857b1e2078741'
          },
          mindfulness: {
            title: 'Mindfulness',
            url: '643813588/rendition/1080p?loc=external&signature=838b184ca0f0bed95b3946d0161b227f542a30a2e87d617fab2de03c7fccb67f'
          }
        }
      }
    },
    methods: {
      buttonTitle(video) {
        if (this.remaining.length === 1) {
          return "I'm ready"
        } else {
          return this.videos[video].title
        }
      },
      play(video) {
        pull(this.remaining, video)
        this.$root.videos.current = this.videos[video].url
        this.$root.videos.next = ['666909932/rendition/1080p?loc=external&signature=2cb6f94a94c8a789f54b344b7432487f33370769faa5cd11498b7f39f99f9c3d',
          ...map(this.remaining, video => this.videos[video].url)]
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => vm.play(to.meta.video))
    }
  }
</script>
