<template>
  <div class="container is-max-desktop">
    <div class="buttons is-centered">
      <router-link to="/building-my-skills" class="button is-large">I see, go on</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643812891/rendition/1080p?loc=external&signature=5aca1e72abac44419fd2da0bf251d5d5296955eeec0650ac0c0322451cc71d0b'
        vm.$root.videos.next = ['643812990/rendition/1080p?loc=external&signature=3c3654a71b7701e46c16659f4ee29eff0e217deefe8792439b84803ce409b2d7']
      })
    }
  }
</script>
