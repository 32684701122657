<template>
  <transition mode="out-in"
    enter-active-class="animate__animated animate__fadeInRight"
    leave-active-class="animate__animated animate__fadeOutLeft" style="--animate-duration: 0.1s;">

    <div class="container is-max-desktop" :key="currentStep">
      <template v-if="currentStep == 1">
        <div class="block">
          <h1 class="title has-text-centered has-text-white">Find a quiet place where you can focus</h1>
        </div>
        <div class="block">
          <div class="buttons is-centered">
            <button class="button is-large" @click="nextStep">I'm ready</button>
          </div>
        </div>
      </template>
      <template v-if="currentStep == 2">
        <div class="block">
          <h1 class="title has-text-centered has-text-white">Great, have you got a small item to hold?</h1>
        </div>
        <div class="block">
          <div class="buttons is-centered">
            <router-link to="/pushing-paper" class="button is-large">Yes, I'm ready</router-link>
          </div>
        </div>
      </template>
    </div>
  </transition>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643811488/rendition/1080p?loc=external&signature=4901cbff7d0d536da3bcfa46263118cdebecd787c71785228e110076ec8b05bd'
        vm.$root.videos.next = ['643811596/rendition/1080p?loc=external&signature=d7949ca6ae734cf7534049765696dd74298107fc971993017e9e041a69c7de1b']
      })
    },
    data() {
      return {
        currentStep: 1
      }
    },
    methods: {
      nextStep() { if (this.currentStep < 2) { this.currentStep += 1} }
    }
  }
</script>
