<template>
  <div class="container is-max-desktop">
    <div class="columns">
      <div class="column">
        <h1 class="title has-text-centered has-text-white">
          Before we get started, please check the following...
        </h1>
        <div class="box">
          <div class="field">
            <label class="b-checkbox checkbox"><input type="checkbox" v-model="sessionNotes.consents.use">
              <span class="check is-info"></span>
              <span class="control-label">
                I understand this product is a psychological tool under development and I know
                how my responses and information will be used.
                <a href="https://www.lumind.co.nz/consent/" target="_blank">View full consent information.</a>
              </span>
            </label>
          </div>
        </div>
        <div class="box">
          <div class="field">
            <label class="b-checkbox checkbox"><input type="checkbox" v-model="sessionNotes.consents.help">
              <span class="check is-info"></span>
              <span class="control-label">
                I understand that human therapists will not be viewing my responses. If you need
                urgent help at any time, please contact the appropriate service as shown on the
                <a href="https://www.lumind.co.nz/consent/#helplines" target="_blank">Lumind website</a>.
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="buttons is-centered">
          <button class="button is-large" @click="$router.push('/introduction')" :disabled="!answered">I'm Ready</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        sessionNotes: this.$root.sessionNotes
      }
    },
    computed: {
      answered() {
        return this.sessionNotes.consents.use && this.sessionNotes.consents.help
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '666903099/rendition/1080p?loc=external&signature=ab04deac1ffd143ccad768cd21b0bf135b406c279595e7512ec39e2967e06388'
        vm.$root.videos.next = ['646216058/rendition/1080p?loc=external&signature=c0420f9010fafe84eab57104e0e75471fc804153015e2a65415bfb55239516f2']
      })
    }
  }
</script>
