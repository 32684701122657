<template>
  <router-view></router-view>
</template>

<script>
  const defaultSessionNotes = () => {
    return {
      consents: {
        use: false, help: false
      },
      introduction: '',
      identifyingSignals: {
        mind: [], body: [], actions: []
      },
      signalScenarios: {
        what: '', where: '', when: '', who: ''
      },
      typesOfQuickFixes: [],
      goalSetting: {
        goal: '', firstStep: '', secondStep: ''
      }
    }
  }

  export default {
    data() {
      return {
        videos: {
          current: null,
          next: []
        },
        sessionNotes: defaultSessionNotes()
      }
    },
    created() {
      if (localStorage.getItem('currentRoute')) {
        this.$router.push('/continue')
      }
    },
    watch: {
      sessionNotes: {
        handler(newSessionNotes) {
          localStorage.setItem('sessionNotes', JSON.stringify(newSessionNotes))
        }, deep: true
      }
    },
    methods: {
      reset() {
        localStorage.removeItem('currentRoute')
        localStorage.removeItem('sessionNotes')
        this.sessionNotes = defaultSessionNotes()
      }
    }
  }
</script>

<style>
  html, body {
    height: 100vh;
    width: 100vw;
  }
  body {
    background-color: #515257;
  }
  #app {
    position: fixed;
  }
</style>