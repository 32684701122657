<template>
  <div class="container is-max-desktop">
    <div class="block">
      <h1 class="title has-text-centered has-text-white is-spaced">
        Thank you for supporting our mission to make therapy more accessible.
      </h1>

      <h2 class="subtitle has-text-centered has-text-white" v-if="!sent">
        To help make our product better, we need your feedback. Please enter your email
        address to receive your session notes and to complete a 3 minute survey about our product.
      </h2>

      <article class="message is-success" v-if="sent">
        <div class="message-body">
          Thank you! Check your inbox for your session notes.
        </div>
      </article>
    </div>
    <div class="columns is-centered" v-if="!sent">
      <div class="column is-two-thirds">
        <div class="field is-grouped">
          <p class="control is-expanded">
            <input class="input" :class="{'is-danger': error}" type="email" placeholder="Email Address" v-model="email">
          </p>
          <p class="control">
            <button class="button" :class="{'is-danger': error, 'is-loading': sending}" @click="sendSessionNotes()" :disabled="email == ''">Send</button>
          </p>
        </div>
        <p class="help is-danger" v-if="error">There was an error while sending the email. Please check your email address.</p>
      </div>
    </div>
    <div class="buttons is-centered" v-if="sent">
      <router-link to="/welcome" class="button is-large">Start again</router-link>
      <router-link to="/practice-skills" class="button is-large">Practice skills</router-link>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        email: '',
        error: false,
        sending: false,
        sent: false
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643823523/rendition/1080p?loc=external&signature=0b1060c9be8fbee7f7342cefe42a1eb0bc29b4d47a84fcd6c4eca6d15b489559'
        vm.$root.videos.next = []
      })
    },
    methods: {
      sendSessionNotes() {
        this.sending = true

        axios.post('https://spotlight.lumind.co.nz/.netlify/functions/session-notes', {
          email: this.email,
          sessionNotes: this.$root.sessionNotes
        }).then(() => {
          this.error = false
          this.sent = true

          this.$root.reset()
        }).catch(() => {
          this.error = true
        }).finally(() => {
          this.sending = false
        })
      }
    }
  }
</script>
