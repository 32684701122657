<template>
  <div ref="videos" class="is-overlay is-clipped" :class="$style.videos">
    <VideoPlayer v-for="(url, index) in allVideoUrls" :key="url" :url="url" :current="index === 0"
      @ended="showInterface = true" @restarted="showInterface = false" :zoomed="zoomed" @zoom="zoomed = $event"/>
  </div>

  <section ref="interface" :class="$style.interface" class="hero is-fullheight" v-show="showInterface">
    <div class="hero-body" :class="$style.heroBody">
      <router-view :class="$style.routerView" @scrollTop="$refs.interface.scrollTop = 0"/>
    </div>
  </section>
</template>

<script>
  import compact from 'lodash.compact'

  export default {
    data() {
      return {
        videos: this.$root.videos,
        showInterface: false,
        zoomed: true
      }
    },
    mounted() {
      const ratio = this.$refs.videos.clientWidth / this.$refs.videos.clientHeight

      if (ratio < 1) {
        this.zoomed = false
      }
    },
    computed: {
      allVideoUrls() {
        return compact([this.videos.current, ...this.videos.next])
      }
    },
    watch: {
      'videos.current'() {
        this.showInterface = false
      }
    }
  }
</script>


<style lang="scss" module>
  .videos {
    background-color: black;

    > * {
      display: none;
    }

    > :first-child {
      display: block;
    }
  }

  .interface {
    min-height: 0 !important;
    height: 100%;
    overflow: auto;
    position: relative;
    z-index: 1;

    backdrop-filter: blur(10px);
    background-color: rgba(black, 0.5);

    @include desktop {
      .heroBody {
        align-items: flex-end !important;
      }
    }
    .routerView {
      @include desktop {
        min-height: 40% !important;
      }
    }
  }
</style>