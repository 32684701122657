<template>
  <div class="container is-max-desktop">
    <div class="columns">
      <div class="column">
        <h1 class="title has-text-centered has-text-white">Is this concept of Willingness starting to make sense for you?</h1>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="buttons is-centered">
          <router-link to="/building-my-skills" class="button is-large">Yes</router-link>
          <router-link to="/clarifying-willingness" class="button is-large">No</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643812760/rendition/1080p?loc=external&signature=de227dde446c1b2f000de822f0bf90f4776bd26cf3e467f9780d32ec1dab5314'
        vm.$root.videos.next = [
          '643812891/rendition/1080p?loc=external&signature=5aca1e72abac44419fd2da0bf251d5d5296955eeec0650ac0c0322451cc71d0b',
          '643812990/rendition/1080p?loc=external&signature=3c3654a71b7701e46c16659f4ee29eff0e217deefe8792439b84803ce409b2d7'
        ]
      })
    }
  }
</script>
