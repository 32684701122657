<template>
  <div class="container is-max-desktop">
    <div class="buttons is-centered">
      <router-link to="/short-vs-long-consequences" class="button is-large">I'd love an example</router-link>
      <router-link to="/intro-to-willingness" class="button is-large">Let's move on to the next exercise</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643811912/rendition/1080p?loc=external&signature=1ebd549adb1a1b61778ca7886fa74e53ed84764d18183425c18f8e9e0f629e3c'
        vm.$root.videos.next = [
          '643811958/rendition/1080p?loc=external&signature=e6c5221964fb3558286933d2db86728168790f2de034694e2668ba5241e4e7f6',
          '643812390/rendition/1080p?loc=external&signature=d2ba4e1034b02fd662cbc78af7c3f541ff683b1fc233f65ccfc24b497d2c048f'
        ]
      })
    }
  }
</script>
