<template>
  <div class="container">
    <div class="columns">
      <div class="column">
        <h1 class="title has-text-centered has-text-white">Which signs of anxiety come up for you most often?</h1>
      </div>
    </div>
    <div class="columns">
      <div class="column" v-for="({title, values}, sign) in signs" :key="sign">
        <div class="box">
          <h1 class="title">{{ title }}</h1>
          <div class="field" v-for="value in values" :key="`${sign}-${value}`">
            <label class="b-checkbox checkbox">
              <input type="checkbox" :value="value" v-model="sessionNotes.identifyingSignals[sign]">
              <span class="check is-info"></span>
              <span class="control-label">{{ value }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="buttons is-centered">
          <button class="button is-large" @click="$router.push('/signal-validation')" :disabled="!answered">I've chosen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643811073/rendition/1080p?loc=external&signature=28a1051b863e253bce9a11473ba8d9d803159c3e0a8ade851d7a4532c75d1ef4'
        vm.$root.videos.next = ['643811118/rendition/1080p?loc=external&signature=419f0b49aa656b1b7d9af32f969ce8a16d70600c42ee01cc9363cbe078296f81']
      })
    },
    data() {
      return {
        signs: {
          mind: {
            title: 'Mind',
            values: [
              'Worrying',
              'Fearing the worst',
              'Difficulty concentrating',
              'Unwanted or racing thoughts',
              'Irritability'
            ]
          },
          body: {
            title: 'Body',
            values: [
              'Tight chest',
              'Breathing rapidly (hyperventilation)',
              'Sweating',
              'Trembling or shaking',
              'Feeling weak, tired or sick',
              'Tense muscles (eg neck)',
              'Racing heart rate'
            ]
          },
          actions: {
            title: 'Actions',
            values: [
              'Avoiding situations, places or people',
              'Intense emotional reactions',
              'Difficulty socialising or connecting with others',
              'Unable to get to sleep, or stay asleep'
            ]
          }
        },
        sessionNotes: this.$root.sessionNotes
      }
    },
    computed: {
      answered() {
        return this.sessionNotes.identifyingSignals.mind.length > 0 ||
          this.sessionNotes.identifyingSignals.body.length > 0 ||
          this.sessionNotes.identifyingSignals.actions.length > 0
      }
    }
  }
</script>
