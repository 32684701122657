<template>
  <div class="container is-max-desktop">
    <div class="buttons is-centered">
      <router-link to="/how-willingness-works" class="button is-large">So... Willingness?</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643812582/rendition/1080p?loc=external&signature=e478d402b9ea34d25265b41f786faed2f1bd0cd97501aaf1dd5d030ac98fc22b'
        vm.$root.videos.next = ['643812760/rendition/1080p?loc=external&signature=de227dde446c1b2f000de822f0bf90f4776bd26cf3e467f9780d32ec1dab5314']
      })
    }
  }
</script>
