import { createRouter, createWebHistory } from 'vue-router'
import Landing from '../views/Landing.vue'
import Start from '../views/Start.vue'
import Continue from '../views/Continue.vue'
import PracticeSkills from '../views/PracticeSkills.vue'

import Welcome from '../views/Welcome.vue'
import Introduction from '../views/Introduction.vue'

import Struggling from '../views/Struggling.vue'
import Thriving from '../views/Thriving.vue'

import Education from '../views/Education.vue'
import IdentifyingSignals from '../views/IdentifyingSignals.vue'
import SignalValidation from '../views/SignalValidation.vue'
import SignalScenarios from '../views/SignalScenarios.vue'

import AcceptancePrep from '../views/AcceptancePrep.vue'

import PushingPaper from '../views/PushingPaper.vue'
import TypesOfQuickFixes from '../views/TypesOfQuickFixes.vue'
import OptionToExpandConsequences from '../views/OptionToExpandConsequences.vue'
import ShortVsLongConsequences from '../views/ShortVsLongConsequences.vue'

import IntroToWillingness from '../views/IntroToWillingness.vue'
import PushingPaperPart2 from '../views/PushingPaperPart2.vue'
import HowWillingnessWorks from '../views/HowWillingnessWorks.vue'
import ClarifyingWillingness from '../views/ClarifyingWillingness.vue'

import BuildingMySkills from '../views/BuildingMySkills.vue'
import Tools from '../views/Tools.vue'

import BridgeToAction from '../views/BridgeToAction.vue'
import SummarySoFar from '../views/SummarySoFar.vue'
import GoalSetting from '../views/GoalSetting.vue'
import MakingACommitment from '../views/MakingACommitment.vue'

import Conclusion from '../views/Conclusion.vue'

const routes = [
  { path: '/', component: Landing },
  { path: '/continue', component: Continue,
    beforeEnter() {
      if (!localStorage.getItem('currentRoute')) {
        return { path: '/', replace: true }
      }
    }
  },
  { path: '/practice-skills', component: PracticeSkills },
  { path: '/welcome', component: Start, meta: { saveRoute: true }, children: [
    { path: '', component: Welcome },
    { path: '/introduction', component: Introduction },
    { path: '/struggling', component: Struggling },
    { path: '/thriving', component: Thriving },
    { path: '/education', component: Education },
    { path: '/identifying-signals', component: IdentifyingSignals },
    { path: '/signal-validation', component: SignalValidation },
    { path: '/signal-scenarios', component: SignalScenarios },
    { path: '/acceptance-prep', component: AcceptancePrep },
    { path: '/pushing-paper', component: PushingPaper },
    { path: '/types-of-quick-fixes', component: TypesOfQuickFixes },
    { path: '/option-to-expand-consequences', component: OptionToExpandConsequences },
    { path: '/short-vs-long-consequences', component: ShortVsLongConsequences },
    { path: '/intro-to-willingness', component: IntroToWillingness },
    { path: '/pushing-paper-part-2', component: PushingPaperPart2 },
    { path: '/how-willingness-works', component: HowWillingnessWorks },
    { path: '/clarifying-willingness', component: ClarifyingWillingness },
    { path: '/building-my-skills', component: BuildingMySkills },
    { path: '/breathing', component: Tools, meta: { video: 'breathing' } },
    { path: '/grounding', component: Tools, meta: { video: 'grounding' } },
    { path: '/mindfulness', component: Tools, meta: { video: 'mindfulness' } },
    { path: '/bridge-to-action', component: BridgeToAction },
    { path: '/summary-so-far', component: SummarySoFar },
    { path: '/goal-setting', component: GoalSetting },
    { path: '/making-a-commitment', component: MakingACommitment },
    { path: '/conclusion', component: Conclusion }
  ] }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  if (to.meta.saveRoute) {
    localStorage.setItem('currentRoute', to.path)
  }
})

export default router
