<template>
  <div class="container is-max-desktop">
    <div class="block">
      <h1 class="title has-text-centered has-text-white">
        These tools will take about five minutes each and require a quiet place
      </h1>
      <h2 class="subtitle has-text-centered has-text-white">
        What would you like to start with?
      </h2>
    </div>
    <div class="block">
      <div class="buttons is-centered">
        <router-link to="/breathing" class="button is-large">Breathing</router-link>
        <router-link to="/grounding" class="button is-large">Grounding</router-link>
        <router-link to="/mindfulness" class="button is-large">Mindfulness</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643812990/rendition/1080p?loc=external&signature=3c3654a71b7701e46c16659f4ee29eff0e217deefe8792439b84803ce409b2d7'
        vm.$root.videos.next = [
          '643814114/rendition/1080p?loc=external&signature=48497c3f59cacfaab56f6ef1faf9c2a8ab15131f145615c878c58ec1780d22d6',
          '643813071/rendition/1080p?loc=external&signature=acb99606008b0587b8d71e21b2db56ef4a662b60137740a3355857b1e2078741',
          '643813588/rendition/1080p?loc=external&signature=838b184ca0f0bed95b3946d0161b227f542a30a2e87d617fab2de03c7fccb67f'
        ]
      })
    }
  }
</script>
