<template>
  <div class="container is-max-desktop">
    <div class="buttons is-centered">
      <router-link to="/education" class="button is-large">I'd like to know more</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643810765/rendition/1080p?loc=external&signature=86873bfee1725270f252e523ffbd99c27519a4bac7e09bd0a3b11e85be692de1'
        vm.$root.videos.next = ['666903327/rendition/1080p?loc=external&signature=9bcb7581b5387677170d200d3c1c05b4727c12f9bc151694e22dcec10f6b800a']
      })
    }
  }
</script>
