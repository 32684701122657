<template>
  <transition mode="out-in"
    enter-active-class="animate__animated animate__fadeInRight"
    leave-active-class="animate__animated animate__fadeOutLeft" style="--animate-duration: 0.1s;">

    <div class="container is-max-desktop" :key="currentStep">
      <template v-if="currentStep < 5">
        <div class="block">
          <h1 class="title has-text-centered has-text-white">{{ question.title }}</h1>
          <h2 class="subtitle has-text-centered has-text-light">
            {{ question.subtitle }}
          </h2>
        </div>
        <div class="block">
          <textarea class="textarea" rows="2" :placeholder="question.placeholder" v-model="sessionNotes.signalScenarios[currentStepKey]"></textarea>
        </div>
        <div class="block">
          <div class="buttons is-centered">
            <button v-if="currentStep > 1" class="button is-large" @click="previousStep">Go back</button>
            <button class="button is-large" @click="nextStep" :disabled="!answered">All done, next</button>
          </div>
        </div>
      </template>
      <template v-else-if="currentStep === 5">
        <div class="block">
          <h1 class="title has-text-centered has-text-white">Review</h1>
        </div>
        <div class="box">
          <div class="content">
            <template v-for="({title}, step) in questions" :key="step">
              <h2>{{ title }}</h2>
              <p>{{ sessionNotes.signalScenarios[step] }}</p>
            </template>
          </div>
        </div>
        <div class="block">
          <div class="buttons is-centered">
            <button class="button is-large" @click="previousStep">Go back</button>
            <router-link to="/acceptance-prep" class="button is-large">I've completed this section</router-link>
          </div>
        </div>
      </template>
    </div>
  </transition>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643811175/rendition/1080p?loc=external&signature=a8e0814108ddf490d38fb4cfd63b2a2cb33434893d7efa865ba2b4f97b26f5e9'
        vm.$root.videos.next = ['643811488/rendition/1080p?loc=external&signature=4901cbff7d0d536da3bcfa46263118cdebecd787c71785228e110076ec8b05bd']
      })
    },
    data() {
      return {
        currentStep: 1,
        steps: { 1: 'what', 2: 'where', 3: 'when', 4: 'who' },
        questions: {
          what: {
            title: 'What sets off anxiety for you?',
            subtitle: 'What causes you the most anxiety? For example, is it a situation, person, place, or thing?',
            placeholder: 'E.g., social situations where I might make a fool of myself, fear of my mind going blank during a presentation...'
          },
          where: {
            title: 'Where are you when this anxiety shows up?',
            subtitle: "What's the location?",
            placeholder: 'E.g., work, home, visiting a friend, shopping, in the car, near water, near a particular animal...'
          },
          when: {
            title: 'When does the anxiety show up?',
            subtitle: 'For example, is there a certain time of day or part of the week that anxiety shows up?',
            placeholder: 'E.g., first thing in the morning; morning; midday; afternoon; evening; before bed; the day before work; most of the time...'
          },
          who: {
            title: 'Who is most likely to be around when the anxiety is present?',
            subtitle: 'Sometimes we feel our anxiety is worse with other people around. If this is the case for you, who are those people that seem to trigger your anxiety/influence your feelings of anxiety?',
            placeholder: "E.g., I'm usually alone; partner; parent; sibling; friend; colleague; groups of people; when people are close to me..."
          }
        },
        sessionNotes: this.$root.sessionNotes
      }
    },
    computed: {
      question() {
        return this.questions[this.currentStepKey]
      },
      answered() {
        return this.sessionNotes.signalScenarios[this.currentStepKey] &&
          this.sessionNotes.signalScenarios[this.currentStepKey] !== ''
      },
      currentStepKey() {
        return this.steps[this.currentStep]
      }
    },
    methods: {
      nextStep() {
        if (this.currentStep < 5) {
          this.currentStep += 1
          this.$emit('scrollTop')
        }
      },
      previousStep() {
        if (this.currentStep > 1) {
          this.currentStep -= 1
          this.$emit('scrollTop')
        }
      }
    }
  }
</script>
