<template>
  <section :class="$style.continue" class="hero is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <p class="title has-text-white">
          It looks like you've been here before.
        </p>
        <p class="subtitle has-text-white">
          Would you like to continue where you left off?
        </p>
        <div class="buttons is-centered">
          <button class="button is-large" @click="startAgain()">Start again</button>
          <button class="button is-large" @click="continueOn()">Continue</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    methods: {
      startAgain() {
        localStorage.removeItem('currentRoute')
        localStorage.removeItem('sessionNotes')
        this.$router.replace('/')
      },
      continueOn() {
        const sessionNotes = localStorage.getItem('sessionNotes')
        const savedRoute = localStorage.getItem('currentRoute')

        if (sessionNotes) { this.$root.sessionNotes = JSON.parse(sessionNotes) }
        this.$router.replace(savedRoute)
      }
    }
  }
</script>

<style lang="scss" module>
  .continue {
    min-height: 0 !important;
    height: 100%;
    overflow: hidden;

    background-color: black;
  }
</style>