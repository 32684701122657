<template>
  <div class="container is-max-desktop">
    <div class="columns">
      <div class="column">
        <h1 class="title has-text-centered has-text-white">
          If anxiety wasn't pushing me around, what could I do that I'm not doing now?
        </h1>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label has-text-white">My goal is...</label>
          <div class="control">
            <input class="input" type="text" v-model="sessionNotes.goalSetting.goal" placeholder="E.g., Spend more time with friends I care about.">
          </div>
        </div>

        <div class="field">
          <label class="label has-text-white">The first small step towards my goal is...</label>
          <div class="control">
            <input class="input" type="text" v-model="sessionNotes.goalSetting.firstStep" placeholder="E.g., Send text message to friend asking what day this week could work to catch-up.">
          </div>
        </div>

        <div class="field">
          <label class="label has-text-white">The second small step towards my goal is...</label>
          <div class="control">
            <input class="input" type="text" v-model="sessionNotes.goalSetting.secondStep" placeholder="E.g., Organise to meet at beach or cafe.">
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="buttons is-centered">
          <button class="button is-large" @click="$router.push('/making-a-commitment')" :disabled="!answered">I've finished with my steps</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        sessionNotes: this.$root.sessionNotes
      }
    },
    computed: {
      answered() {
        return this.sessionNotes.goalSetting.goal && this.sessionNotes.goalSetting.goal !== '' &&
          this.sessionNotes.goalSetting.firstStep && this.sessionNotes.goalSetting.firstStep !== '' &&
          this.sessionNotes.goalSetting.secondStep && this.sessionNotes.goalSetting.secondStep !== ''
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643814549/rendition/1080p?loc=external&signature=d210080ea769c2365491d06cee6b712e65a705ec44b6cddbfe15876ca82ec335'
        vm.$root.videos.next = ['666910298/rendition/1080p?loc=external&signature=7170345fcf950e37c09d89e921acbd7461c69e4c512881c2269f97a00bfb05eb']
      })
    }
  }
</script>
