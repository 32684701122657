import 'animate.css'
import './scss/global.scss'
import { createApp } from 'vue'
import App from './App.vue'

import VideoPlayer from './components/VideoPlayer'
import router from './router'

const app = createApp(App).use(router)

app.component('VideoPlayer', VideoPlayer)
app.mount('#app')
