<template>
  <div class="container is-max-desktop">
    <div class="buttons is-centered">
      <router-link to="/intro-to-willingness" class="button is-large">Thanks for the example</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643811958/rendition/1080p?loc=external&signature=e6c5221964fb3558286933d2db86728168790f2de034694e2668ba5241e4e7f6'
        vm.$root.videos.next = ['643812390/rendition/1080p?loc=external&signature=d2ba4e1034b02fd662cbc78af7c3f541ff683b1fc233f65ccfc24b497d2c048f']
      })
    }
  }
</script>
