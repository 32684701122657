<template>
  <div class="is-overlay is-clipped" @click="toggle()">
    <video class="is-overlay is-clipped" :class="[$style.video, {[$style.zoomed]: zoomed}]"
      :src="`https://player.vimeo.com/progressive_redirect/playback/${url}`" playsinline preload="auto" ref="video"
      @play="paused = false" @pause="paused = true" @ended="ended = true"
      @loadstart="loading = true" @canplay="loading = false"></video>

    <span class="icon" :class="$style.loading" v-show="loading">
      <i class="fa-solid fa-spinner fa-pulse fa-fw fa-10x"></i>
    </span>

    <div :class="$style.playButton" @click.stop="$refs.video.play()" v-if="!loading && paused && !ended">
      <i class="fa-solid fa-play-circle fa-fw fa-10x"></i>
    </div>

    <div :class="$style.videoControls">
      <div :class="$style.button" v-if="!loading && !paused && !ended && !zoomed" @click.stop="$emit('zoom', true)">
        <i class="fa-solid fa-magnifying-glass-plus fa-fw fa-2x"></i>
      </div>

      <div :class="$style.button" v-if="!loading && !paused && !ended && zoomed" @click.stop="$emit('zoom', false)">
        <i class="fa-solid fa-magnifying-glass-minus fa-fw fa-2x"></i>
      </div>

      <div :class="$style.button" v-if="!loading && (!paused || ended)" @click.stop="restart()">
        <i class="fa-solid fa-rotate-left fa-fw fa-2x"></i>
      </div>

      <div :class="$style.button" v-if="!paused && !loading && !ended" @click.stop="skip()">
        <i class="fa-solid fa-forward-fast fa-fw fa-2x"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      paused: true,
      ended: false
    }
  },
  props: {
    url: { type: String, required: true },
    current: { type: Boolean, default: false },
    zoomed: { type: Boolean, required: true }
  },
  mounted() {
    if (this.current) { this.$refs.video.play() }
  },
  watch: {
    current(isCurrent) {
      this.ended = false

      if (isCurrent) {
        this.$refs.video.play()
      } else {
        this.$refs.video.pause()
        this.$refs.video.currentTime = 0
      }
    },
    ended(isEnded) {
      if(isEnded) { this.$emit('ended') }
    }
  },
  methods: {
    toggle() {
      if (!this.ended) {
        this.paused ? this.$refs.video.play() : this.$refs.video.pause()
      }
    },
    skip() {
      this.$refs.video.pause()
      this.ended = true
    },
    restart() {
      this.$refs.video.currentTime = 0

      if (this.ended) {
        this.ended = false
        this.$emit('restarted')
        this.$refs.video.play()
      }
    }
  }
}
</script>

<style lang="scss" module>
  .video {
    height: 100%;
    width: 100%;

    &.zoomed {
      object-fit: cover;
      object-position: center 15%;
    }
  }

  .loading {
    color: rgba(white, 0.6);
    filter: drop-shadow(0 0 10px rgba(black, 0.4));

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .playButton {
    color: white;
    filter: drop-shadow(0 0 10px rgba(black, 0.4));

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    transition: all 0.1s ease-in-out;

    &:hover {
      filter: drop-shadow(0 0 10px rgba(black, 0.8));
    }
  }

  .videoControls {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem;
    color: white;
    z-index: 2;
    filter: drop-shadow(1px 1px 1px rgba(black, 1));

    .button {
      display: inline-block;
    }
  }

</style>
