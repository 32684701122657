<template>
  <div class="container is-max-desktop">
    <div class="buttons is-centered">
      <router-link to="/types-of-quick-fixes" class="button is-large">I'm ready</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643811596/rendition/1080p?loc=external&signature=d7949ca6ae734cf7534049765696dd74298107fc971993017e9e041a69c7de1b'
        vm.$root.videos.next = ['643811853/rendition/1080p?loc=external&signature=924335929227636cef089357d095f22e4ae608fb90f23a36517434ae62575390']
      })
    }
  }
</script>
