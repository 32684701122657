<template>
  <div class="container is-max-desktop">
    <div class="buttons is-centered">
      <router-link to="/struggling" @click="setAnswer('struggling')" class="button is-large is-danger">
        <span class="icon">
          <i class="fa-duotone fa-signal-bars-fair"></i>
        </span>
        <span>Struggling</span>
      </router-link>

      <router-link to="/struggling" @click="setAnswer('surviving')" class="button is-large is-warning">
        <span class="icon">
          <i class="fa-duotone fa-signal-bars-good"></i>
        </span>
        <span>Surviving</span>
      </router-link>

      <router-link to="/thriving" @click="setAnswer('thriving')" class="button is-large is-success">
        <span class="icon">
          <i class="fa-duotone fa-signal-bars"></i>
        </span>
        <span>Thriving</span>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        sessionNotes: this.$root.sessionNotes
      }
    },
    methods: {
      setAnswer(answer) {
        this.sessionNotes.introduction = answer
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '646216058/rendition/1080p?loc=external&signature=c0420f9010fafe84eab57104e0e75471fc804153015e2a65415bfb55239516f2'
        vm.$root.videos.next = [
          '643810765/rendition/1080p?loc=external&signature=86873bfee1725270f252e523ffbd99c27519a4bac7e09bd0a3b11e85be692de1',
          '643807549/rendition/1080p?loc=external&signature=41526f82c9429ebf40488d2a6ce1c537545e6015e634d1be28e5f2c4892b7b90'
        ]
      })
    }
  }
</script>
