<template>
  <section :class="$style.landing" class="hero is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <p class="title">
          <img src="../assets/logo.svg" :class="$style.logo">
        </p>
        <div class="buttons is-centered">
          <router-link to="/welcome" class="button is-large">Start session</router-link>
          <router-link to="/practice-skills" class="button is-large">Practice skills</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {}
</script>

<style lang="scss" module>
  .landing {
    min-height: 0 !important;
    height: 100%;
    overflow: hidden;

    background: {
      image: url('../assets/desk.jpg');
      repeat: no-repeat;
      position: center center;
      size: cover;
    }

    .logo {
      width: 250px;
    }
  }
</style>