<template>
  <div class="container is-max-desktop">
    <div class="buttons is-centered">
      <router-link to="/pushing-paper-part-2" class="button is-large">I'm ready with something to hold</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643812390/rendition/1080p?loc=external&signature=d2ba4e1034b02fd662cbc78af7c3f541ff683b1fc233f65ccfc24b497d2c048f'
        vm.$root.videos.next = ['643812582/rendition/1080p?loc=external&signature=e478d402b9ea34d25265b41f786faed2f1bd0cd97501aaf1dd5d030ac98fc22b']
      })
    }
  }
</script>
