<template>
  <div class="container is-max-desktop">
    <div class="buttons is-centered">
      <router-link to="/education" class="button is-large">Tell me more</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643807549/rendition/1080p?loc=external&signature=41526f82c9429ebf40488d2a6ce1c537545e6015e634d1be28e5f2c4892b7b90'
        vm.$root.videos.next = ['666903327/rendition/1080p?loc=external&signature=9bcb7581b5387677170d200d3c1c05b4727c12f9bc151694e22dcec10f6b800a']
      })
    }
  }
</script>
