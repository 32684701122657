<template>
  <div class="container is-max-desktop">
    <div class="buttons is-centered">
      <router-link to="/identifying-signals" class="button is-large">Okay, go on</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '666903327/rendition/1080p?loc=external&signature=9bcb7581b5387677170d200d3c1c05b4727c12f9bc151694e22dcec10f6b800a'
        vm.$root.videos.next = ['643811073/rendition/1080p?loc=external&signature=28a1051b863e253bce9a11473ba8d9d803159c3e0a8ade851d7a4532c75d1ef4']
      })
    }
  }
</script>
