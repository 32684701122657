<template>
  <div class="container is-max-desktop">
    <div class="buttons is-centered">
      <router-link to="/signal-scenarios" class="button is-large">I'm ready</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643811118/rendition/1080p?loc=external&signature=419f0b49aa656b1b7d9af32f969ce8a16d70600c42ee01cc9363cbe078296f81'
        vm.$root.videos.next = ['643811175/rendition/1080p?loc=external&signature=a8e0814108ddf490d38fb4cfd63b2a2cb33434893d7efa865ba2b4f97b26f5e9']
      })
    }
  }
</script>
