<template>
  <section :class="$style.skills" class="hero is-fullheight">
    <div class="hero-body">
      <div class="container is-max-widescreen">
        <nav class="level">
          <div class="level-left">
            <div class="level-item">
              <p class="title has-text-white">Practice Skills</p>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <router-link to="/" class="button">
                <span class="icon is-small">
                  <i class="fa-solid fa-arrow-left"></i>
                </span>
                <span>Back</span>
              </router-link>
            </div>
          </div>
        </nav>

        <div class="columns">
          <div class="column">
            <h2 class="subtitle has-text-white has-text-centered">Grounding</h2>
            <figure class="image is-16by9">
              <iframe src="https://player.vimeo.com/video/643813071?h=5aa7e7d718&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Grounding"></iframe>
            </figure>
          </div>

          <div class="column">
            <h2 class="subtitle has-text-white has-text-centered">Mindfulness</h2>
            <figure class="image is-16by9">
              <iframe src="https://player.vimeo.com/video/643813588?h=c5ffb70e9d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Mindfulness"></iframe>
            </figure>
          </div>

          <div class="column">
            <h2 class="subtitle has-text-white has-text-centered">Breathing</h2>
            <figure class="image is-16by9">
              <iframe src="https://player.vimeo.com/video/643814114?h=5748363569&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Breathing"></iframe>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {}
</script>

<style lang="scss" module>
  .skills {
    min-height: 0 !important;
    height: 100%;
    overflow: auto;

    background: {
      image: url('../assets/desk.jpg');
      repeat: no-repeat;
      position: center center;
      size: cover;
    }
  }
</style>