<template>
  <div class="container is-max-desktop">
    <div class="box" v-for="({title, detail}) in fixes" :key="title">
      <label class="b-checkbox checkbox has-text-weight-bold">
        <input type="checkbox" :value="title" v-model="sessionNotes.typesOfQuickFixes">
        <span class="check is-info"></span>
        <span class="control-label">{{ title }}</span>
      </label>

      <p>{{ detail }}</p>
    </div>
    <div class="buttons is-centered">
      <button class="button is-large" @click="$router.push('/option-to-expand-consequences')" :disabled="!answered">I've chosen</button>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$root.videos.current = '643811853/rendition/1080p?loc=external&signature=924335929227636cef089357d095f22e4ae608fb90f23a36517434ae62575390'
        vm.$root.videos.next = ['643811912/rendition/1080p?loc=external&signature=1ebd549adb1a1b61778ca7886fa74e53ed84764d18183425c18f8e9e0f629e3c']
      })
    },
    data() {
      return {
        fixes: [
          {
            title: 'Hiding/Escaping',
            detail: 'Hiding away (or escaping) from people, places, situations, or activities.'
          },
          {
            title: 'Distraction',
            detail: 'Distracting yourself using things like social media, TV, or gaming.'
          },
          {
            title: 'Numbing',
            detail: '"Zoning Out" through the use of things like medication, drugs, alcohol, or sleep.'
          },
          {
            title: 'Suppression',
            detail: 'Pushing away or “pushing down” anxious thoughts or feelings.'
          },
          {
            title: 'Self-bullying',
            detail: 'Negative self-talk, self criticism.'
          }
        ],
        sessionNotes: this.$root.sessionNotes
      }
    },
    computed: {
      answered() {
        return this.sessionNotes.typesOfQuickFixes.length > 0
      }
    }
  }
</script>
